<template>
  <el-container>
    <el-main>
      <h2 v-if="departmentId !== 'null'" class="depTitle">科室绩效数据填报</h2>
      <!-- 点击操作kehsi基期数据填报 -->
      <el-table :data="tableData" style="width: 95%; margin: 0 auto" border center>
        <el-table-column type="index" label="序号" width="55" align="center">
        </el-table-column>
        <el-table-column prop="year" label="基期年份" align="center">
        </el-table-column>
        <el-table-column prop="updateDate" label="更新时间" align="center">
        </el-table-column>
        <el-table-column label="填报状态" align="center">
          <template slot-scope="scope">
            <div class="activeStatus" v-if="scope.row.baseStatus == 1">√</div>
            <div class="noStatus" v-else>√</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template style="display: flex" slot-scope="scope">
            <el-button size="mini" type="primary" @click="listJixiao(scope.row)"><i class="el-icon-notebook-2"></i></el-button>
            <!-- <el-button size="mini" type="primary" @click="editJixiao(scope.row)"
  ><i class="el-icon-document-copy"></i
></el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination background @current-change="handleDontCurrentChange" :current-page="DontpageNum" :page-size="DontpageSize" layout="prev, pager, next" :total="Donttotal">
      </el-pagination>
    </el-main>
    <!-- 点击操作的第一个按钮弹出框 -->
    <el-dialog :title="zhubiaoTitle" :visible.sync="HospitaldatadialogVisible" width="80%" append-to-body :before-close="HospitaldatadialogVisibleClose">
      <el-table :data="IndicatorstableData" style="width: 95%; margin: 0 auto" border center>
        <el-table-column type="index" label="序号" width="55" align="center">
        </el-table-column>
        <el-table-column prop="name" label="数据名称" width="180" align="center">
        </el-table-column>
        <el-table-column prop="updateDate" label="更新时间" align="center">
        </el-table-column>
        <el-table-column label="填报状态" align="center">
          <template slot-scope="scope">
            <div class="activeStatus" v-if="scope.row.status == 0">√</div>
            <div class="noStatus" v-else>√</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template style="display: flex" slot-scope="scope">
            <el-button size="mini" type="primary" @click="indicators(scope.row)"><i class="el-icon-edit"></i></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!-- 人员架构 -->
    <el-dialog title="人员架构" :visible.sync="saveDepartmentBaseOnedialogVisible" width="80%" append-to-body :before-close="saveDepartmentBaseOnedialogVisibleClose">
      <el-form style="width: 100%" :inline="true" label-width="120px" :model="saveDepartmentBaseOneModel" ref="saveDepartmentBaseOneRef" :rules="saveDepartmentBaseOneRules">
        <el-form-item label="在职人数：" prop="people">
          <div class="hosform">
            <el-input v-model="saveDepartmentBaseOneModel.people"></el-input>
            <div class="hosdanwei">(人)</div>
          </div>
        </el-form-item>
        <el-form-item label="其中：医师：" prop="doctorPeople">
          <div class="hosform">
            <el-input v-model="saveDepartmentBaseOneModel.doctorPeople"></el-input>
            <div class="hosdanwei">(人)</div>
          </div>
        </el-form-item>
        <el-form-item label="护理人员：" prop="nursePeople">
          <div class="hosform">
            <el-input v-model="saveDepartmentBaseOneModel.nursePeople"></el-input>
            <div class="hosdanwei">(人)</div>
          </div>
        </el-form-item>
        <el-form-item label="技师：" prop="technicianPeople">
          <div class="hosform">
            <el-input v-model="saveDepartmentBaseOneModel.technicianPeople"></el-input>
            <div class="hosdanwei">(人)</div>
          </div>
        </el-form-item>
        <el-form-item label="行政后勤：" prop="workPeople">
          <div class="hosform">
            <el-input v-model="saveDepartmentBaseOneModel.workPeople"></el-input>
            <div class="hosdanwei">(人)</div>
          </div>
        </el-form-item>
        <el-form-item label="其他人员：" prop="otherPeople">
          <div class="hosform">
            <el-input v-model="saveDepartmentBaseOneModel.otherPeople"></el-input>
            <div class="hosdanwei">(人)</div>
          </div>
        </el-form-item>
        <el-form-item class="depar-dia">
          <div class="btnCen">
            <el-button type="primary" @click="saveDepartmentBaseOne">提交数据</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 科室结构 -->
    <el-dialog title="科室结构" :visible.sync="saveDepartmentBaseTwodialogVisible" width="900px" append-to-body :before-close="saveDepartmentBaseTwodialogVisibleClose">
      <el-form style="width: 100%" :inline="true" label-width="130px" :model="saveDepartmentBaseTwoModel" ref="saveDepartmentBaseTwoRef" :rules="saveDepartmentBaseTwoRules">
        <el-form-item label="编制床位：" prop="beds">
          <div class="hosform">
            <el-input v-model="saveDepartmentBaseTwoModel.beds"></el-input>
            <div class="hosdanwei">(张)</div>
          </div>
        </el-form-item>
        <el-form-item label="实际开放床位：" prop="bedsOne">
          <div class="hosform">
            <el-input v-model="saveDepartmentBaseTwoModel.bedsOne"></el-input>
            <div class="hosdanwei">(张)</div>
          </div>
        </el-form-item>
        <el-form-item label="设备总数：" prop="total">
          <div class="hosform">
            <el-input v-model="saveDepartmentBaseTwoModel.total"></el-input>
            <div class="hosdanwei">(台)</div>
          </div>
        </el-form-item>
        <el-form-item label="其中：DR：" prop="totalOne">
          <div class="hosform">
            <el-input v-model="saveDepartmentBaseTwoModel.totalOne"></el-input>
            <div class="hosdanwei">(台)</div>
          </div>
        </el-form-item>
        <el-form-item label="CT：" prop="totalTwo">
          <div class="hosform">
            <el-input v-model="saveDepartmentBaseTwoModel.totalTwo"></el-input>
            <div class="hosdanwei">(台)</div>
          </div>
        </el-form-item>
        <el-form-item label="MRI：" prop="totalThree">
          <div class="hosform">
            <el-input v-model="saveDepartmentBaseTwoModel.totalThree"></el-input>
            <div class="hosdanwei">(台)</div>
          </div>
        </el-form-item>
        <el-form-item label="超声：" prop="totalFour">
          <div class="hosform">
            <el-input v-model="saveDepartmentBaseTwoModel.totalFour"></el-input>
            <div class="hosdanwei">(台)</div>
          </div>
        </el-form-item>
        <el-form-item label="其它：" prop="totalFive">
          <div class="hosform">
            <el-input v-model="saveDepartmentBaseTwoModel.totalFive"></el-input>
            <div class="hosdanwei">(台)</div>
          </div>
        </el-form-item>
        <el-form-item label="手术室：" prop="room">
          <div class="hosform">
            <el-input v-model="saveDepartmentBaseTwoModel.room"></el-input>
            <div class="hosdanwei">(间)</div>
          </div>
        </el-form-item>
        <el-form-item label="xxxx：" prop="" style="visibility: hidden">
          <div class="hosform">
            <el-input></el-input>
            <div class="hosdanwei">(x)</div>
          </div>
        </el-form-item>
        <el-form-item class="depar-dia">
          <div class="btnCen">
            <el-button type="primary" @click="saveDepartmentBaseTwo">提交数据</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 运营效率 -->
    <el-dialog title="运营效率" :visible.sync="saveDepartmentBaseThreedialogVisible" width="1140px" :before-close="saveDepartmentBaseThreedialogVisibleClose" top="1vh" append-to-body>
      <el-form style="width: 100%" :inline="true" label-width="180px" :model="saveDepartmentBaseThreeModel" ref="saveDepartmentBaseThreeRef" :rules="saveDepartmentBaseThreeRules">
        <el-form-item label="门急诊人次：" prop="person">
          <div class="hosform">
            <el-input v-model="saveDepartmentBaseThreeModel.person"></el-input>
            <div class="hosdanwei">(人次)</div>
          </div>
        </el-form-item>
        <el-form-item label="其中入院率：" prop="personOne">
          <div class="hosform">
            <el-input v-model="saveDepartmentBaseThreeModel.personOne"></el-input>
            <div class="hosdanwei">(人次)</div>
          </div>
        </el-form-item>
        <el-form-item label="每职工平均门急诊：" prop="personTwo">
          <div class="hosform">
            <el-input v-model="saveDepartmentBaseThreeModel.personTwo"></el-input>
            <div class="hosdanwei">(人次)</div>
          </div>
        </el-form-item>
        <el-form-item label="床位使用率：" prop="personThree">
          <div class="hosform">
            <el-input v-model="saveDepartmentBaseThreeModel.personThree"></el-input>
            <div class="hosdanwei" style="visibility: hidden">(人次)</div>
          </div>
        </el-form-item>
        <el-form-item label="床位周转率：" prop="personFour">
          <div class="hosform">
            <el-input v-model="saveDepartmentBaseThreeModel.personFour"></el-input>
            <div class="hosdanwei" style="visibility: hidden">(人次)</div>
          </div>
        </el-form-item>
        <el-form-item label="每职工平均住院床日：" prop="personFive">
          <div class="hosform">
            <el-input v-model="saveDepartmentBaseThreeModel.personFive"></el-input>
            <div class="hosdanwei">(床日)</div>
          </div>
        </el-form-item>
        <el-form-item label="平均住院天数：" prop="personSix">
          <div class="hosform">
            <el-input v-model="saveDepartmentBaseThreeModel.personSix"></el-input>
            <div class="hosdanwei">(天)</div>
          </div>
        </el-form-item>
        <el-form-item label="住院患者手术率：" prop="personSeven">
          <div class="hosform">
            <el-input v-model="saveDepartmentBaseThreeModel.personSeven"></el-input>
            <div class="hosdanwei" style="visibility: hidden">(人次)</div>
          </div>
        </el-form-item>
        <el-form-item label="年手术量：" prop="operation">
          <div class="hosform">
            <el-input v-model="saveDepartmentBaseThreeModel.operation"></el-input>
            <div class="hosdanwei">(台次)</div>
          </div>
        </el-form-item>
        <el-form-item label="其中：三四级手术占比：" prop="operationOne">
          <div class="hosform">
            <el-input v-model="saveDepartmentBaseThreeModel.operationOne"></el-input>
            <div class="hosdanwei" style="visibility: hidden">(人次)</div>
          </div>
        </el-form-item>
        <el-form-item label="介入手术占比：" prop="operationTwo">
          <div class="hosform">
            <el-input v-model="saveDepartmentBaseThreeModel.operationTwo"></el-input>
            <div class="hosdanwei" style="visibility: hidden">(人次)</div>
          </div>
        </el-form-item>
        <el-form-item label="日间手术占比：" prop="operationThree">
          <div class="hosform">
            <el-input v-model="saveDepartmentBaseThreeModel.operationThree"></el-input>
            <div class="hosdanwei" style="visibility: hidden">(人次)</div>
          </div>
        </el-form-item>
        <el-form-item label="每手术室日均手术台数：" prop="avgNum">
          <div class="hosform">
            <el-input v-model="saveDepartmentBaseThreeModel.avgNum"></el-input>
            <div class="hosdanwei">(台次)</div>
          </div>
        </el-form-item>
        <el-form-item label="DR台日均检查人次：" prop="drNum">
          <div class="hosform">
            <el-input v-model="saveDepartmentBaseThreeModel.drNum"></el-input>
            <div class="hosdanwei">(人次)</div>
          </div>
        </el-form-item>
        <el-form-item label="CT台日均检查人次：" prop="ctNum">
          <div class="hosform">
            <el-input v-model="saveDepartmentBaseThreeModel.ctNum"></el-input>
            <div class="hosdanwei">(人次)</div>
          </div>
        </el-form-item>
        <el-form-item label="MRI台日均检查人次：" prop="mriNum">
          <div class="hosform">
            <el-input v-model="saveDepartmentBaseThreeModel.mriNum"></el-input>
            <div class="hosdanwei">(人次)</div>
          </div>
        </el-form-item>
        <el-form-item label="超声台日均检查人次：" prop="ultrasonicNum">
          <div class="hosform">
            <el-input v-model="saveDepartmentBaseThreeModel.ultrasonicNum"></el-input>
            <div class="hosdanwei">(人次)</div>
          </div>
        </el-form-item>
        <el-form-item label="xxxx：" prop="" style="visibility: hidden">
          <div class="hosform">
            <el-input></el-input>
            <div class="hosdanwei">(x)</div>
          </div>
        </el-form-item>
        <el-form-item class="depar-dia">
          <div class="btnCen">
            <el-button type="primary" @click="saveDepartmentBaseThree">提交数据</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 薪酬水平 -->
    <el-dialog title="薪酬水平" :visible.sync="saveDepartmentBaseFourdialogVisible" width="900px" append-to-body :before-close="saveDepartmentBaseFourdialogVisibleClose">
      <el-form style="width: 100%" :inline="true" label-width="130px" :model="saveDepartmentBaseFourModel" ref="saveDepartmentBaseFourRef" :rules="saveDepartmentBaseFourRules">
        <el-form-item label="科室人均绩效：" prop="avgPerformance">
          <div class="hosform">
            <el-input v-model="saveDepartmentBaseFourModel.avgPerformance"></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item label="其中：医师：" prop="doctorPerformance">
          <div class="hosform">
            <el-input v-model="saveDepartmentBaseFourModel.doctorPerformance"></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item label="护理人员：" prop="nursePerformance">
          <div class="hosform">
            <el-input v-model="saveDepartmentBaseFourModel.nursePerformance"></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item label="技师：" prop="technicianPerformance">
          <div class="hosform">
            <el-input v-model="saveDepartmentBaseFourModel.technicianPerformance"></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item label="行政后勤：" prop="workPerformance">
          <div class="hosform">
            <el-input v-model="saveDepartmentBaseFourModel.workPerformance"></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item label="其他人员：" prop="otherPerformance">
          <div class="hosform">
            <el-input v-model="saveDepartmentBaseFourModel.otherPerformance"></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item class="depar-dia">
          <div class="btnCen">
            <el-button type="primary" @click="saveDepartmentBaseFour">提交数据</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 点击操作绩效分配结构 -->
    <el-dialog title="绩效分配结构" :visible.sync="EditdialogVisible" width="45%" append-to-body>
      <el-form style="width: 100%" :model="Performance" ref="PerformanceRef" :rules="PerformanceRules" :inline="true" label-width="120px">
        <el-form-item label="机构数：" prop="name">
          <div class="hosform">
            <el-input v-model="Performance.name"></el-input>
            <div class="hosdanwei">(个)</div>
          </div>
        </el-form-item>
        <el-form-item label="机构数：" prop="name">
          <div class="hosform">
            <el-input v-model="Performance.name"></el-input>
            <div class="hosdanwei">(个)</div>
          </div>
        </el-form-item>
        <el-form-item label="编制人数：" prop="name">
          <div class="hosform">
            <el-input v-model="Performance.name"></el-input>
            <div class="hosdanwei">(人)</div>
          </div>
        </el-form-item>
        <el-form-item label="编制人数：" prop="name">
          <div class="hosform">
            <el-input v-model="Performance.name"></el-input>
            <div class="hosdanwei">(人)</div>
          </div>
        </el-form-item>
        <el-form-item label="机构数：" prop="name">
          <div class="hosform">
            <el-input v-model="Performance.name"></el-input>
            <div class="hosdanwei">(个)</div>
          </div>
        </el-form-item>
        <el-form-item label="机构数：" prop="name">
          <div class="hosform">
            <el-input v-model="Performance.name"></el-input>
            <div class="hosdanwei">(个)</div>
          </div>
        </el-form-item>
        <el-form-item label="编制人数：" prop="name">
          <div class="hosform">
            <el-input v-model="Performance.name"></el-input>
            <div class="hosdanwei">(人)</div>
          </div>
        </el-form-item>
        <el-form-item label="编制人数：" prop="name">
          <div class="hosform">
            <el-input v-model="Performance.name"></el-input>
            <div class="hosdanwei">(人)</div>
          </div>
        </el-form-item>
        <el-form-item label="机构数：" prop="name">
          <div class="hosform">
            <el-input v-model="Performance.name"></el-input>
            <div class="hosdanwei">(个)</div>
          </div>
        </el-form-item>
        <el-form-item label="机构数：" prop="name">
          <div class="hosform">
            <el-input v-model="Performance.name"></el-input>
            <div class="hosdanwei">(个)</div>
          </div>
        </el-form-item>
        <el-form-item label="编制人数：" prop="name">
          <div class="hosform">
            <el-input v-model="Performance.name"></el-input>
            <div class="hosdanwei">(人)</div>
          </div>
        </el-form-item>
        <el-form-item label="编制人数：" prop="name">
          <div class="hosform">
            <el-input v-model="Performance.name"></el-input>
            <div class="hosdanwei">(人)</div>
          </div>
        </el-form-item>
        <el-form-item class="depar-dia">
          <div class="btnCen">
            <el-button type="primary" @click="editdialogKeshi">提交数据</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </el-container>
</template>

<script>
import _qs from "qs";
export default {
  props: ["keshiId"],
  data() {
    // 请输入数字
    var NumcheckAge = (rule, value, callback) => {
      if (!value && String(value) !== "0") {
        return callback(new Error("不能为空"));
      }
      setTimeout(() => {
        // console.log("number", Number.isInteger(value))
        if (!/^\d+$|^\d*\.\d+$/g.test(value)) {
          callback(new Error("请输入数字！"));
        } else {
          callback();
        }
      }, 100);
    };
    return {
      // 科室 人员架构 添加
      saveDepartmentBaseOneModel: {
        people: "", // 在职人员
        doctorPeople: "", // 其中：医师
        nursePeople: "", // 护理人员
        technicianPeople: "", // 技师
        workPeople: "", // 行政后勤
        otherPeople: "", // 其他人员
      },
      saveDepartmentBaseOneRules: {
        people: [
          { required: true, validator: NumcheckAge, trigger: "blur" },
        ], // 在职人员
        doctorPeople: [
          { required: true, validator: NumcheckAge, trigger: "blur" },
        ], // 其中：医师
        nursePeople: [
          { required: true, validator: NumcheckAge, trigger: "blur" },
        ], // 护理人员
        technicianPeople: [
          { required: true, validator: NumcheckAge, trigger: "blur" },
        ], // 技师
        workPeople: [
          { required: true, validator: NumcheckAge, trigger: "blur" },
        ], // 行政后勤
        otherPeople: [
          { required: true, validator: NumcheckAge, trigger: "blur" },
        ], // 其他人员
      },
      // 科室 科室结构 添加
      saveDepartmentBaseTwoModel: {
        beds: "", // 编制床位
        bedsOne: "", // 实际开放床位
        total: "", // 设备总数
        totalOne: "", // DR
        totalTwo: "", // CT
        totalThree: "", // MRI
        totalFour: "", // 超声
        totalFive: "", // 其它
        room: "", // 手术室
      },
      saveDepartmentBaseTwoRules: {
        beds: [
          { required: true, validator: NumcheckAge, trigger: "blur" },
        ], // 编制床位
        bedsOne: [
          { required: true, validator: NumcheckAge, trigger: "blur" },
        ], // 实际开放床位
        total: [
          { required: true, validator: NumcheckAge, trigger: "blur" },
        ], // 设备总数
        totalOne: [
          { required: true, validator: NumcheckAge, trigger: "blur" },
        ], // DR
        totalTwo: [
          { required: true, validator: NumcheckAge, trigger: "blur" },
        ], // CT
        totalThree: [
          { required: true, validator: NumcheckAge, trigger: "blur" },
        ], // MRI
        totalFour: [
          { required: true, validator: NumcheckAge, trigger: "blur" },
        ], // 超声
        totalFive: [
          { required: true, validator: NumcheckAge, trigger: "blur" },
        ], // 其它
        room: [
          { required: true, validator: NumcheckAge, trigger: "blur" },
        ], // 手术室
      },
      // 科室 运营效率 添加
      saveDepartmentBaseThreeModel: {
        person: "", // 门急诊人次
        personOne: "", // 其中入院率
        personTwo: "", // 每职工平均门诊急诊人次
        personThree: "", // 床位使用率
        personFour: "", // 床位周转率
        personFive: "", // 每职工平均住院床日
        personSix: "", // 平均住院天数
        personSeven: "", // 住院患者手术率
        operation: "", // 年手术量
        operationOne: "", // 其中：三四级手术真占比
        operationTwo: "", // 介入手术占比
        operationThree: "", // 日间手术占比
        avgNum: "", // 每手术室日均手术台数
        drNum: "", // DR台日均检查人次
        ctNum: "", // CT台日均检查人次
        mriNum: "", // MRI台日均检查人次
        ultrasonicNum: "", // 超声台日均检查人次
      },
      saveDepartmentBaseThreeRules: {
        person: [
          { required: true, validator: NumcheckAge, trigger: "blur" },
        ], // 门急诊人次
        personOne: [
          { required: true, validator: NumcheckAge, trigger: "blur" },
        ], // 其中入院率
        personTwo: [
          { required: true, validator: NumcheckAge, trigger: "blur" },
        ], // 每职工平均门诊急诊人次
        personThree: [
          { required: true, validator: NumcheckAge, trigger: "blur" },
        ], // 床位使用率
        personFour: [
          { required: true, validator: NumcheckAge, trigger: "blur" },
        ], // 床位周转率
        personFive: [
          { required: true, validator: NumcheckAge, trigger: "blur" },
        ], // 每职工平均住院床日
        personSix: [
          { required: true, validator: NumcheckAge, trigger: "blur" },
        ], // 平均住院天数
        personSeven: [
          { required: true, validator: NumcheckAge, trigger: "blur" },
        ], // 住院患者手术率
        operation: [
          { required: true, validator: NumcheckAge, trigger: "blur" },
        ], // 年手术量
        operationOne: [
          { required: true, validator: NumcheckAge, trigger: "blur" },
        ], // 其中：三四级手术真占比
        operationTwo: [
          { required: true, validator: NumcheckAge, trigger: "blur" },
        ], // 介入手术占比
        operationThree: [
          { required: true, validator: NumcheckAge, trigger: "blur" },
        ], // 日间手术占比
        avgNum: [
          { required: true, validator: NumcheckAge, trigger: "blur" },
        ], // 每手术室日均手术台数
        drNum: [
          { required: true, validator: NumcheckAge, trigger: "blur" },
        ], // DR台日均检查人次
        ctNum: [
          { required: true, validator: NumcheckAge, trigger: "blur" },
        ], // CT台日均检查人次
        mriNum: [
          { required: true, validator: NumcheckAge, trigger: "blur" },
        ], // MRI台日均检查人次
        ultrasonicNum: [
          { required: true, validator: NumcheckAge, trigger: "blur" },
        ], // 超声台日均检查人次
      },
      // 科室 薪酬水平 添加
      saveDepartmentBaseFourModel: {
        avgPerformance: "", // 科室人均绩效
        doctorPerformance: "", // 其中：医师
        nursePerformance: "", // 护理人员
        technicianPerformance: "", // 技师
        workPerformance: "", // 行政后勤
        otherPerformance: "", // 其他人员
      },
      saveDepartmentBaseFourRules: {
        avgPerformance: [
          { required: true, validator: NumcheckAge, trigger: "blur" },
        ], // 科室人均绩效
        doctorPerformance: [
          { required: true, validator: NumcheckAge, trigger: "blur" },
        ], // 其中：医师
        nursePerformance: [
          { required: true, validator: NumcheckAge, trigger: "blur" },
        ], // 护理人员
        technicianPerformance: [
          { required: true, validator: NumcheckAge, trigger: "blur" },
        ], // 技师
        workPerformance: [
          { required: true, validator: NumcheckAge, trigger: "blur" },
        ], // 行政后勤
        otherPerformance: [
          { required: true, validator: NumcheckAge, trigger: "blur" },
        ], // 其他人员
      },
      // 绩效分配结构
      Performance: {
        name: "",
      },
      // 绩效分配结构规则
      PerformanceRules: {
        name: [
          {
            required: true,
            message: "请输入绩效分配结构",
            trigger: "blur",
          },
        ],
      },
      // 表格数据
      DeplisttableData: [],
      // 医院id
      // 总页数
      Donttotal: 1,
      // 每页页数
      DontpageNum: 1,
      // 每页显示条数
      DontpageSize: 10,
      HospitaldatadialogVisible: false,
      // 添加年份
      AddDateModel: {
        // 年份名称
        year: "",
      },
      // 年份规则
      AddDateRules: {
        year: [
          {
            required: true,
            message: "请选择年份",
            trigger: "blur",
          },
        ],
      },
      // 绩效分配结构弹出框
      EditdialogVisible: false,
      // 人员架构
      saveDepartmentBaseOnedialogVisible: false,
      // 科室结构
      saveDepartmentBaseTwodialogVisible: false,
      // 运营效率
      saveDepartmentBaseThreedialogVisible: false,
      // 薪酬水平
      saveDepartmentBaseFourdialogVisible: false,
      // 表格数据
      tableData: [],
      // 指标表格
      IndicatorstableData: [],
      // 医院id
      hospitalId: window.sessionStorage.getItem("hospitalId"),
      //这条数据id
      id: "",
      // 指标名称
      zhubiaoTitle: "",
      // 科室年份数据id
      YearId: "",
      name: "",
      type: "",
      departId: this.keshiId,
      departmentId: window.sessionStorage.getItem("departmentId"),
    };
  },
  watch: {
    keshiId: {
      handler(newName, oldName) {
        this.departId = newName;
        this.getDepartmentYearList();
      },
      deep: true,
    },
  },
  created() {
    // 0 医院用户   1 科室用户
    if (this.departmentId !== 'null') {
      this.departId = window.sessionStorage.getItem("departmentId");
    }
    this.getDepartmentYearList();
  },
  methods: {
    // 点击操作绩效分配结构
    editJixiao(row) {
      console.log(row);
      this.EditdialogVisible = true;
    },
    // 科室 人员架构 添加
    saveDepartmentBaseOne() {
      this.$refs.saveDepartmentBaseOneRef.validate(async (valid) => {
        if (!valid) return;
        let data = _qs.stringify({
          businessId: this.YearId,
          people: this.saveDepartmentBaseOneModel.people, // 在职人员
          doctorPeople: this.saveDepartmentBaseOneModel.doctorPeople, // 其中：医师
          nursePeople: this.saveDepartmentBaseOneModel.nursePeople, // 护理人员
          technicianPeople:
          this.saveDepartmentBaseOneModel.technicianPeople, // 技师
          workPeople: this.saveDepartmentBaseOneModel.workPeople, // 行政后勤
          otherPeople: this.saveDepartmentBaseOneModel.otherPeople, // 其他人员
        });
        let res = await this.$axios.saveDepartmentBaseOne(data);
        // console.log(res);
        if (res.data.code == 401) {
          this.$router.push("/login");
        } else if (res.data.code == 500) {
          this.$message({
            message: "添加科室 人员架构失败",
            type: "error",
          });
        } else if (res.status == 200) {
          this.$message({
            message: "添加科室 人员架构成功",
            type: "success",
          });
          this.saveDepartmentBaseOnedialogVisible = false;
          this.$refs.saveDepartmentBaseOneRef.resetFields();
          this.departmentList();
        }
      });
    },
    saveDepartmentBaseOnedialogVisibleClose() {
      this.saveDepartmentBaseOnedialogVisible = false;
      this.$refs.saveDepartmentBaseOneRef.resetFields();
    },
    saveDepartmentBaseFourdialogVisibleClose() {
      this.saveDepartmentBaseFourdialogVisible = false;
      this.$refs.saveDepartmentBaseFourRef.resetFields();
    },
    saveDepartmentBaseThreedialogVisibleClose() {
      this.saveDepartmentBaseThreedialogVisible = false;
      this.$refs.saveDepartmentBaseThreeRef.resetFields();
    },
    saveDepartmentBaseTwodialogVisibleClose() {
      this.saveDepartmentBaseTwodialogVisible = false;
      this.$refs.saveDepartmentBaseTwoRef.resetFields();
    },
    // 科室 科室结构 添加
    saveDepartmentBaseTwo() {
      this.$refs.saveDepartmentBaseTwoRef.validate(async (valid) => {
        if (!valid) return;
        let data = _qs.stringify({
          businessId: this.YearId,
          beds: this.saveDepartmentBaseTwoModel.beds, // 编制床位
          bedsOne: this.saveDepartmentBaseTwoModel.bedsOne, // 实际开放床位
          total: this.saveDepartmentBaseTwoModel.total, // 设备总数
          totalOne: this.saveDepartmentBaseTwoModel.totalOne, // DR
          totalTwo: this.saveDepartmentBaseTwoModel.totalTwo, // CT
          totalThree: this.saveDepartmentBaseTwoModel.totalThree, // MRI
          totalFour: this.saveDepartmentBaseTwoModel.totalFour, // 超声
          totalFive: this.saveDepartmentBaseTwoModel.totalFive, // 其它
          room: this.saveDepartmentBaseTwoModel.room, // 手术室
        });
        let res = await this.$axios.saveDepartmentBaseTwo(data);
        // console.log(res);
        if (res.data.code == 401) {
          this.$router.push("/login");
        } else if (res.data.code == 500) {
          this.$message({
            message: "添加科室 科室结构失败",
            type: "error",
          });
        } else if (res.status == 200) {
          this.$message({
            message: "添加科室 科室结构成功",
            type: "success",
          });
          this.saveDepartmentBaseTwodialogVisible = false;
          this.$refs.saveDepartmentBaseTwoRef.resetFields();
          this.departmentList();
        }
      });
    },
    // 科室 运营效率 添加
    saveDepartmentBaseThree() {
      this.$refs.saveDepartmentBaseThreeRef.validate(async (valid) => {
        if (!valid) return;
        let data = _qs.stringify({
          businessId: this.YearId,
          person: this.saveDepartmentBaseThreeModel.person, // 门急诊人次
          personOne: this.saveDepartmentBaseThreeModel.personOne, // 其中入院率
          personTwo: this.saveDepartmentBaseThreeModel.personTwo, // 每职工平均门诊急诊人次
          personThree: this.saveDepartmentBaseThreeModel.personThree, // 床位使用率
          personFour: this.saveDepartmentBaseThreeModel.personFour, // 床位周转率
          personFive: this.saveDepartmentBaseThreeModel.personFive, // 每职工平均住院床日
          personSix: this.saveDepartmentBaseThreeModel.personSix, // 平均住院天数
          personSeven: this.saveDepartmentBaseThreeModel.personSeven, // 住院患者手术率
          operation: this.saveDepartmentBaseThreeModel.operation, // 年手术量
          operationOne:
          this.saveDepartmentBaseThreeModel.operationOne, // 其中：三四级手术真占比
          operationTwo:
          this.saveDepartmentBaseThreeModel.operationTwo, // 介入手术占比
          operationThree:
          this.saveDepartmentBaseThreeModel.operationThree, // 日间手术占比
          avgNum: this.saveDepartmentBaseThreeModel.avgNum, // 每手术室日均手术台数
          drNum: this.saveDepartmentBaseThreeModel.drNum, // DR台日均检查人次
          ctNum: this.saveDepartmentBaseThreeModel.ctNum, // CT台日均检查人次
          mriNum: this.saveDepartmentBaseThreeModel.mriNum, // MRI台日均检查人次
          ultrasonicNum:
          this.saveDepartmentBaseThreeModel.ultrasonicNum, // 超声台日均检查人次
        });
        let res = await this.$axios.saveDepartmentBaseThree(data);
        // console.log(res);
        if (res.data.code == 401) {
          this.$router.push("/login");
        } else if (res.data.code == 500) {
          this.$message({
            message: "添加科室 科室结构失败",
            type: "error",
          });
        } else if (res.status == 200) {
          this.$message({
            message: "添加科室 科室结构成功",
            type: "success",
          });
          this.saveDepartmentBaseThreedialogVisible = false;
          this.$refs.saveDepartmentBaseThreeRef.resetFields();
          this.departmentList();
        }
      });
    },
    // 科室 薪酬水平 添加
    saveDepartmentBaseFour() {
      this.$refs.saveDepartmentBaseFourRef.validate(async (valid) => {
        if (!valid) return;
        let data = _qs.stringify({
          businessId: this.YearId,
          avgPerformance:
          this.saveDepartmentBaseFourModel.avgPerformance, // 科室人均绩效
          doctorPerformance:
          this.saveDepartmentBaseFourModel.doctorPerformance, // 其中：医师
          nursePerformance:
          this.saveDepartmentBaseFourModel.nursePerformance, // 护理人员
          technicianPerformance:
          this.saveDepartmentBaseFourModel.technicianPerformance, // 技师
          workPerformance:
          this.saveDepartmentBaseFourModel.workPerformance, // 行政后勤
          otherPerformance:
          this.saveDepartmentBaseFourModel.otherPerformance, // 其他人员
        });
        let res = await this.$axios.saveDepartmentBaseFour(data);
        // console.log(res);
        if (res.data.code == 401) {
          this.$router.push("/login");
        } else if (res.data.code == 500) {
          this.$message({
            message: "添加科室 科室结构失败",
            type: "error",
          });
        } else if (res.status == 200) {
          this.$message({
            message: "添加科室 科室结构成功",
            type: "success",
          });
          this.saveDepartmentBaseFourdialogVisible = false;
          this.$refs.saveDepartmentBaseFourRef.resetFields();
          this.departmentList();
        }
      });
    },
    // 点击2020年-医院主要指标的操作
    indicators(row) {
      this.name = row.name;
      if (row.name == "人员架构") {
        this.saveDepartmentBaseOnedialogVisible = true;
        this.type = 12;
      } else if (row.name == "科室结构") {
        this.saveDepartmentBaseTwodialogVisible = true;
        this.type = 13;
      } else if (row.name == "运营效率") {
        this.saveDepartmentBaseThreedialogVisible = true;
        this.type = 14;
      } else if (row.name == "薪酬水平") {
        this.saveDepartmentBaseFourdialogVisible = true;
        this.type = 15;
      }
      this.getBaseDetails();
    },
    // 回显数据
    async getBaseDetails() {
      let data = {
        businessId: this.YearId,
        type: this.type,
      };
      let res = await this.$axios.getBaseDetails(data);
      // console.log(res);
      if (res.data.code == 401) {
        this.$router.push("/login");
      } else if (res.data.code == 500) {
        this.$message({
          message: "数据回显失败",
          type: "error",
        });
      } else if (res.data.code == 200) {
        if (res.data.data) {
          if (this.name == "人员架构") {
            this.saveDepartmentBaseOneModel = res.data.data;
          } else if (this.name == "科室结构") {
            this.saveDepartmentBaseTwoModel = res.data.data;
          } else if (this.name == "运营效率") {
            this.saveDepartmentBaseThreeModel = res.data.data;
          } else if (this.name == "薪酬水平") {
            this.saveDepartmentBaseFourModel = res.data.data;
          }
        }
      }
    },
    //  医院基础数据基期分页
    handleDontCurrentChange(val) {
      this.DontpageNum = val; //  console.log(`当前页:  ${val}`);
      this.getDepartmentYearList();
    },
    // 点击添加年份额添加
    addYear() {
      this.$refs.AddDateRef.validate(async (valid) => {
        if (valid) {
          let data = _qs.stringify({
            hospitalId: this.hospitalId, //医院id
            year: this.Eyear, //年份 例：2021
          });

          let { data: res } = await this.$axios.addHospitalBusiness(
              data
          );
          // console.log(res);
          if (res.code == 401) {
            this.$router.push("/login");
          } else if (res.code == 500) {
            this.$message({
              message: res.msg,
              type: "error",
            });
          } else if (res.code == 200) {
            this.$message({
              message: "添加医院年份信息成功",
              type: "success",
            });
            this.getHospitalBusinessList();
            this.$refs.AddDateRef.resetFields();
          }
        }
      });
    },
    // 点击操作
    listJixiao(row) {
      this.YearId = row.id;
      this.zhubiaoTitle = row.year + "年绩效数据填报";
      this.HospitaldatadialogVisible = true;
      this.departmentList();
    },
    // 科室数据更新时间列表
    async departmentList() {
      let data = {
        businessId: this.YearId,
      };
      let { data: res } = await this.$axios.departmentList(data);
      // console.log(res)
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 500) {
        this.$message({
          message: "获取科室数据更新时间列表失败",
          type: "error",
        });
      } else if (res.code == 200) {
        let Indarr = res.data;
        let Indicatorstable = [
          {
            name: "人员架构", //数据名称
          },
          {
            name: "科室结构", //数据名称
          },
          {
            name: "运营效率", //数据名称
          },
          {
            name: "薪酬水平", //数据名称
          },
        ];
        for (let i = 0; i < Indarr.length; i++) {
          if (Indarr[i][0] === undefined) {
            Indicatorstable[i].updateDate = "";
            Indicatorstable[i].status = 1;
          } else {
            Indicatorstable[i].updateDate = Indarr[i][0].updateDate;
            Indicatorstable[i].status = Indarr[i][0].status;
          }
        }
        this.IndicatorstableData = Indicatorstable;
      }
    },
    // 医院数据更新时间列表
    async getDepartmentYearList() {
      let data = {
        departmentId: this.departId, //kehsiid
        pageNum: this.DontpageNum, //页数
        pageSize: this.DontpageSize, //每页显示条数
        orderByColumn: "year", //排序参数 year 年份
        isAsc: "desc", //顺序 desc降序 asc升序
      };
      let { data: res } = await this.$axios.getDepartmentYearList(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.tableData = res.rows;
        this.Donttotal = res.total;
      }
    },
    HospitaldatadialogVisibleClose() {
      this.HospitaldatadialogVisible = false;
      this.getDepartmentYearList();
    },
    // 点击绩效分配结构提交数据
    editdialogKeshi() {
      this.$refs.PerformanceRef.validate(async (valid) => {
        if (!valid) return;
        this.EditdialogVisible = false;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.hosform {
  display: flex;
  .hosdanwei {
    margin-left: 10px;
    margin-right: 10px;
    width: 53px;
  }
}

.activeStatus {
  background: rgb(102, 204, 0);
}
.noStatus,
.activeStatus {
  width: 25px;
  height: 25px;
  color: white;
  line-height: 25px;
  text-align: center;
  margin: 0 auto;
}

.noStatus {
  background: #ccc;
}
.depTitle {
  display: flex;
  margin: 0 auto;
  margin-bottom: 30px;
}

.depar-dia {
  width: 100%;
}

/deep/ .el-radio-button__inner {
  width: 100px;
}
.el-pagination {
  margin-top: 20px;
}

.btnCen {
  text-align: center;
  .el-button {
    width: 150px;
  }
}
</style>
